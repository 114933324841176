import React from 'react';
import MaskedInput from 'react-text-mask';

import { FieldProps } from 'formik';

export const defaultPhoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const allCountriesPhoneMask = ['+', /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const phoneReplace = (phone: string | null | undefined): string => (phone ? phone.replace(/\D/g, '') : '');

export const phoneFormat = (phone: string | null | undefined): string => (phone ? phone.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '+1 ($1) $2-$3') : '');

interface Props {
	placeholder?: string;
	fieldProps: FieldProps;
	className?: string;
	mask?: Array<string | RegExp>;
	withReplace?: boolean;
	autocomplete?: null | 'off' | string;
	touchedOnFocus?: boolean;
}

export const CustomPhoneControl: React.FC<Props> = ({
	placeholder = '',
	fieldProps: { field, form },
	className = 'form-control',
	mask = defaultPhoneMask,
	withReplace = true,
	autocomplete = 'new-password',
	touchedOnFocus = false,
}) => {
	const value = React.useMemo(() => phoneFormat(field.value), [field.value]);

	React.useEffect(() => {
		if (value.length === 10 && !value.startsWith('+1')) {
			form.setFieldValue(field.name, `+1${value}`);
		}
	}, []);

	return (
		<MaskedInput
			mask={mask}
			className={className}
			placeholder={placeholder}
			guide
			id={field.name}
			autoComplete={autocomplete}
			onInput={(e) => form.setFieldValue(field.name, withReplace ? `+${phoneReplace(e.target.value)}` : e.target.value)}
			onPaste={(e) => {
				e.preventDefault();
				const pastedData = phoneReplace(e.clipboardData.getData('Text'));
				form.setFieldValue(field.name, pastedData.length > 10 ? `+1${pastedData.slice(-10)}` : `+1${pastedData}`);
			}}
			onBlur={() => {
				form.setFieldTouched(field.name, true);
			}}
			onFocus={() => {
				touchedOnFocus && form.setFieldTouched(field.name, true);
			}}
			value={value}
		/>
	);
};
